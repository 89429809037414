<template>
  <div class="app-container">
    <el-row class="margin-bottom-20">
      <el-col :lg="16" :xs="16" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Título" v-model="searchForm.query"
          v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button @click.stop.prevent="handleSearchFilter" class="margin-left-10" size="mini" type="primary"
          icon="el-icon-search">Buscar</el-button>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button class="margin-left-10" @click.stop.prevent="handleClean" size="mini" type="warning"
          icon="el-icon-delete">Limpiar</el-button>
      </el-col>
    </el-row>
    <!---- Paginacion -->
    <el-row type="flex" justify="end">
      <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
        :total="parseInt(totalPages)">
      </el-pagination>
    </el-row>
    <el-row type="flex" justify="start">
        <el-button @click.stop.prevent="handleCreateFilter" class="margin-left-10" size="mini" type="success"
          icon="el-icon-plus">Agregar efecto</el-button>
    </el-row>
    <!---- Tabla de Filtros ----->
    <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
      highlight-current-row style="width: 100%">
      <el-table-column label="ID" width="100" fixed align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ scope.row._id !== undefined ? scope.row._id : 'No disponible' }}</div>
            <span>{{ scope.row._id !== undefined ? scope.row._id.toString().substr(-4) : 'N/D' }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Título"  align="center" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.title !== undefined ? scope.row.title : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Miniatura" width="200" align="center">
        <template slot-scope="scope">
              <div class="guide-image">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="`${pathServer + scope.row.miniature}`"
                  fit="scale-down"
                  :description="scope.row.description"
                  :preview-src-list="[pathServer + scope.row.miniature]">
                </el-image>
              </div>
        </template>
      </el-table-column>
      <el-table-column label="Efecto" width="200" align="center">
        <template slot-scope="scope">
              <div class="guide-image">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="`${pathServer + scope.row.image}`"
                  fit="scale-down"
                  :description="scope.row.description"
                  :preview-src-list="[pathServer + scope.row.image]">
                </el-image>
              </div>
        </template>
      </el-table-column>
      <el-table-column label="Mostrar en App" width="120" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.showInApp !== undefined" :type="scope.row.showInApp ? 'default' : 'info'">{{ scope.row.showInApp ? 'Sí' : 'No' }}</el-tag>
          <el-tag v-else type="info">N/D</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de Registro" width="180" align="center">
        <template slot-scope="scope">
          <span >{{ scope.row.created_at | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Opciones" fixed="right" width="200" >
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEditFilter(scope.row._id)">Editar</el-button>
          <el-button size="mini" @click="handleDeleteFilter(scope.$index, scope.row)" type="danger">Eliminar</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { search, getPage, deleteFilter } from '@/api/filter.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'Projects',
  data () {
    return {
      pathServer: urlServer,
      searchForm: {
        query: ''
      },
      filterDateRegisterRange: '',
      totalPages: '',
      currentPage: '',
      loading: false,
      sizePerPage: 25,
      locations: null,
      types: null,
      list: []
    }
  },
  mounted () {
    this.handleSearchFilter()
  },
  methods: {
    // ---- Data guides ------
    handleCreateFilter () {
      this.$router.push('filters/create')
    },
    handleDownloadDocument (file) {
    },
    handleEditFilter (id) {
      console.log(id)
      this.$router.push({ name: 'filters.edit', params: { _id: id } })
    },
    handleDeleteFilter (index, row) {
      this.$confirm('Se borrará permanentemente. ¿Desea continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await deleteFilter(row._id)
            .then(response => {
              this.$message({
                showClose: true,
                message: '¡Se eliminó correctamente!',
                type: 'success'
              })

              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Operacion cancelada'
          })
        })
        .finally(() => (this.loading = false))
    },
    // --- data search ----
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.searchForm.query = ''
      this.searchForm.section = undefined
      this.searchForm.isActive = undefined
      this.handleSearchFilter()
    },
    validateStatus (isActive) {
      return isActive ? 'Activo' : 'Inactivo'
    },
    validateSection (section) {
      switch (section) {
        case '1':
          return 'I Sección'
        case '2':
          return 'II Sección'
        case '3':
          return 'III Sección'
        default:
          return 'N/D'
      }
    }
  }
}
</script>

<style lang="css">

</style>
